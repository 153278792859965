<template>
  <div class=container>
    <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center">
              <p><font size="+2">Rational Filters for Focus Analysis </font></p>
              </div></td>
          </tr>
          <tr>
            <td><div align="center" class="style1">
              <p align="left">A fundamental problem in depth from defocus is the measurement of relative defocus between images. The performances of traditional focus operators are inevitably sensitive to the frequency spectra of local scene textures. As a result, focus operators such as the Laplacian or Gaussian result in poor depth estimates. We propose a class of broadband operators that, when used together, provide invariance to scene texture. This class of operators is efficient and produces accurate and dense depth maps. Since the operators are broadband, a small number of them are sufficient for depth estimation of scenes with complex textural properties. Experiments have been conducted on both synthetic and real scenes that demonstrated the performance of the proposed operators. The depth detection gain error was less than 1%, irrespective of texture frequency. Depth accuracy was found to be 0.5~1.2 % of the distance of the object from the imaging optics.</p>
              <div align="left">
                <p>To download the image database used in the experiments click at (<a href="https://cave.cs.columbia.edu/old/databases/rationalFilters/database.tar.gz">Image database</a>). To download the source code used in the experiments click at (<a href="https://cave.cs.columbia.edu/old/databases/rationalFilters/source.tar.gz">Source code</a>). The work is described in: <br>
                  <br>
</p><blockquote>
<div class="publication" data-v-0ff51178=""><b data-v-0ff51178="">"Rational Filters for Passive Depth from Defocus,"<br data-v-0ff51178=""></b><span data-v-0ff51178="">M. Watanabe and S.K. Nayar,<br data-v-0ff51178=""></span><span data-v-0ff51178="">International Journal on Computer Vision,<br data-v-0ff51178=""></span><span data-v-0ff51178="">Vol. 27, No. 3, pp. 203-225, May. 1998<br data-v-0ff51178=""></span> [<a class="url" href="http://www1.cs.columbia.edu/CAVE/publications/pdfs/Watanabe_IJCV98.pdf" target="new" data-v-0ff51178="">PDF</a>] [<a href="reference?bid=109" class="url" data-v-0ff51178="">bib</a>] [<a href="copyright" class="url" data-v-0ff51178="">©</a>] <span data-v-0ff51178=""><a href="projects/categories/project?cid=Computational+Imaging&amp;pid=Depth+from+Defocus" class="url" data-v-0ff51178=""> [Project Page]</a></span><br data-v-0ff51178=""></div>
</blockquote>	  
                  <br>
                  <hr align="left" size="4">
                  <p align="left">last updated :
                    <!--webbot bot="Timestamp" startspan
s-type="EDITED" s-format="%m/%d/%y" -->
        08/30/2007
        <!--webbot
bot="Timestamp" i-checksum="14106" endspan -->
                  </p>
              </div>
            </div></td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>